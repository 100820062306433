ul.nav {
  border-bottom: 1px #e2e2e294 solid;
  padding-bottom: 10px;
}

ul.nav svg {
  display: inline;
}

.break-word {
  word-wrap: break-word;
}

.App {
  text-align: center;
}

.App-logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 40vmin;
  margin-top: 40px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body.ristretto {
  color: #332211;
}
